<template>
  <multiselect class="school-search-select"
               :class="selectClass"
               label="schoolName"
               v-model="value"
               :options="options"
               :multiple="false"
               :close-on-select="true"
               :clear-on-select="false"
               :preserve-search="false"
               track-by="schoolName"
               :maxHeight="200"
               @search-change="searchChange"
               :placeholder="placeholder"
               :taggable="allowTag"
               @tag="addTag"
               :disabled="isDisabled"
               tag-placeholder="Add new School"
               :showNoOptions="false"
               :allow-empty="false"
               select-label=""
               deselect-label="Can't remove this school"
  >
    <template slot="singleLabel" slot-scope="props">
      <div class="option__container">
        <span class="option__schoolName">{{ props.option.schoolName }}</span>
        <br>
        <span class="option__address" v-if="props.option.address">
          {{ props.option.address }}
        </span>
      </div>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__container">
        <div class="option__schoolName"
             :title="props.option.schoolNameFull">
        {{ props.option.schoolName }}
        </div>
        <div class="option__address">{{ props.option.address }}</div>
      </div>
    </template>
  </multiselect>
</template>

<script>
  const Multiselect = () => import('vue-multiselect');
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    props : {
      initial : {
        type    : Object,
        default : null,
      },
      allowTag : {
        type    : Boolean,
        default : true,
      },
      disabled : {
        type    : Boolean,
        default : false,
      },
    },
    data() {
      return {
        search       : '',
        options      : [],
        value        : null,
        defaultLogos : [],
        isDisabled   : false,
        selectClass  : 'is-valid',
      };
    },
    mounted() {
      if (this.initial)
        this.searchChange(this.initial.name);
        

      this.$http.get('api/school/logos')
        .then((res) => {
          this.defaultLogos = res.data;
        });
    },
    components : {
      Multiselect,
    },
    mixins : [
      FilterMixin,
    ],
    watch : {
      'value' : {
        handler(val) {
          this.selectClass = 'is-invalid'
          if (val)
            this.selectClass = 'is-valid';
          
          this.$emit('changed', val);
        },
        deep : true,
      },
    },
    computed : {
      placeholder() {
        return this.allowTag ? 'Search or add a School' : 'Search School';
      },
    },
    methods : {

      /**
       * Search school
       */
      // eslint-disable-next-line no-undef
      searchChange : _.debounce(function (query) {
        this.search = query;
        if (query) {
          this.$http.get('api/school/search', {
            params : {
              name : query,
            },
          }).then(response => {
            this.options = this.formatResult(response.data);
            if (this.initial && this.initial.id) {
              // eslint-disable-next-line no-undef
              const def = _.find(response.data, (o) => {
                return o.school_id == this.initial.id;
              });

              if (def)
                this.value = this.formatResult([def])[0];
              

              this.isDisabled = this.disabled;
            }
          // eslint-disable-next-line no-unused-vars
          }).catch(err => {});
        } else
          this.options = [];
        
      }, 500),

      /**
       * Format school address
       * @param school
       * @return {string}
       */
      formatAddress(school) {
        return [
          school.address1,
          school.address2,
          school.city_name,
          school.postal_code,
          school.state_name,
          school.country_name,
        ].filter(Boolean)
          .join(', ');
      },

      /**
       * Format result for school options
       * @param result
       * @return {Array}
       */
      formatResult(result) {
        const formatted = [];

        // eslint-disable-next-line no-undef
        _.each(result, (o) => {
          formatted.push({
            schoolId   : o.school_id,
            schoolName : this.$options.filters
              .truncate(o.school_name, 40),
            schoolNameFull : o.school_name,
            address        : this.formatAddress(o),
            logoUrl        : o.logo_url,
          });
        });

        return formatted;
      },

      /**
       * Update value on select
       * @param value
       */
      select(value) {
        this.value = value;
      },

      /**
       * Remove value
       */
      remove() {
        this.value = null;
      },

      /**
       * Add new school when new tag is added
       * @param newTag
       */
      addTag(newTag) {
        if (newTag) {
          newTag = newTag.replace( /\s\s+/g, ' ' );
          newTag = newTag.trim();

          this.$http.post('/api/profanity', {
            words : newTag,
          }).then(res => {
            if (!res.data) {
              // eslint-disable-next-line no-undef
              const i = _.random(0, this.defaultLogos.length - 1);
              this.$http.post('api/school', {
                // eslint-disable-next-line camelcase
                school_name : newTag,
                // eslint-disable-next-line camelcase
                logo_url    : this.defaultLogos[i] ?
                  this.defaultLogos[i].asset_url : '',
              }).then(response => {

                const school = {
                  schoolId   : response.data.insertId,
                  schoolName : this.search,
                  address    : '',
                  logoUrl    : '',
                };

                this.options.push(school);
                this.value = school;
              // eslint-disable-next-line no-unused-vars
              }).catch(err => {});
            } else {
              this.$notify({
                group : 'notif',
                type  : 'warn',
                title : 'Warning',
                text  : 'School not allowed.',
              });
            }
          // eslint-disable-next-line no-unused-vars
          }).catch(err => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Error',
              text  : 'Something went wrong.',
            });
          })

        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/school-search-select";
</style>